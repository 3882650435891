.miss-order-fornitore-row > td:first-child {
  background-color: var(--warning);
  font-weight: bold;
  color: white !important;
}
/* .miss-order-fornitore-row table > tbody > tr > td {
  color: white !important;
}
tr.miss-order-fornitore-row > td {
  background-color: var(--warning) !important;
  color: white !important;
} */