tr.FolderRow {
  padding: 0;
}
tr.FolderRow > td {
  padding: 0 !important;
}
tr.FolderRow > td > a {
  display: block;
  width: 100%;
  text-decoration: none;
  /* padding: 0.75rem; */
  /* padding: 50px; */
  /* padding: 0.75rem; */
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
  color: inherit;
}
tr.FolderRow > td > a > .FolderRowContent {
  padding: 0.75rem;
}