.Bad-Permission-Cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--gray-dark);
}
.Bad-Permission-Man {
  color: var(--gray-dark);
  font-size: 60px;
  cursor:help;
}

.Bad-Permission-Man:hover {
  animation: dancing-agent 2s infinite linear;
}

@keyframes dancing-agent {
  from {
    transform: rotate(0deg);
  }

  30% {
    color: darkolivegreen;
    transform: rotate(15deg) scale(1.1);
  }

  60% {
    color: darkorchid;
    transform: rotate(-15deg) scale(1.1);
  }

  to {
    transform: rotate(0deg) scale(1);
  }
}
