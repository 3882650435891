@import '~@fortawesome/fontawesome-free/css/all.css';

.pointer {
  cursor: pointer;
}

.text-red {
  color: red;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: blue;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover {
  color: blue;
  text-decoration: underline;
}

.nav-link.active {
  color: blue !important;
}

.p-mobile-bottom {
  padding-bottom: 80px;
}

.bg-gray-light {
  background-color: #e8e8e8 !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.DateInput_input {
  padding: 6px 10px !important;
  text-rendering: auto !important;
  font: 400 13.3333px Arial !important;
}

.DateInput {
  width: 114px !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #007bff !important;
}

.TabsMobile {
  height: 65px;
}

.VincoloTable td {
  padding: 3px;
}

.VincoloTable th {
  padding: 3px;
}

.form-control[readonly] {
  background-color: #fff;
}

.grey-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #868e96;
}

.grey-title {
  font-size: 1.75rem;
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

h5,
h4,
h6 {
  font-weight: bold;
}

.colortd {
  width: 100px;
  height: 100px;
  text-align: center;
}

.colortd-label {
  text-align: center;
  padding-top: 4px;
}

.flex-1 {
  flex: 1 !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-item > img {
  width: 60% !important;
  display: inline !important;
}

.carousel-inner {
  text-align: center;
  align-items: center;
}

.card-header {
  padding: 0.35rem 1.25rem !important;
}

.rbc-month-view {
  height: 600px !important;
}

.calendar-festa-date {
  background: rgba(230, 0, 0, 0.6);
}

.card-body-calendar {
  padding: 0.35rem !important;
}

.table-schedulatore td,
.table-schedulatore th {
  padding: 0.4rem;
}

.table-schedulatore input,
.table-schedulatore select {
  font-size: 0.75rem;
}

.alarm-date-min {
  font-size: 10px;
}

.event-click-timeline:hover {
  filter: brightness(150%);
}

.field-qr-form-readonly.form-group input.form-control:disabled,
textarea.form-control:disabled {
  border: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.03);
  color: #1f1f1f;
}

#CookielawBanner {
  z-index: 9999999;
}

#tiledesk-container.closed {
  pointer-events: none !important;
}

#tiledesk-container.closed iframe {
  pointer-events: all !important;
}

@media (max-width: 576px) {
  #tiledesk-container.closed iframe {
    width: 60px !important;
    height: 60px !important;
  }
}

/* #tiledesk-container.closed #tiledeskdiv {
    margin-bottom: 100px !important;
} */

.qr-version-site-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 90% !important;
  font-family: monospace;
}

.select-multi-options {
  width: 200px;
}

.ordini-fonitori-cards > .btn-sm {
  font-size: 11px;
}

.card-ordini-fornitori > .card-header {
  padding: 0.75rem 1.25rem !important;
}

.buttons-righe-ordini > .btn-sm {
  font-size: 11px;
}

.font-11 {
  font-size: 11px;
}

.table-righe-ordini-fornitore {
  border-collapse: separate;
  border-spacing: 0;
}

.sticky-col-right {
  right: 0;
  position: sticky;
  table-layout: auto;
  top: auto;
  width: 60px;
  z-index: 1;
}

.sticky-col-right-buttons {
  right: 55px;
  left: 60px;
  position: sticky;
  width: 70px;
  z-index: 1;
  top: auto;
}

.scroller-div {
  overflow-x: auto;
  overflow-y: none;
  width: 100%;
}

.table-hover tbody tr:hover {
  background-color: rgb(255, 255, 0, 0.3);
}

.disabled-checkbox{
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.stats-table {
  font-size: 14px;
}

.DateRangePicker__block {
  display: block;
  border: 1px solid #CCC;
  padding-top: 2px;
}

.table-info > td {
  background-color: lightyellow;
}

.table-warning > td {
  /*  orange with 50% opacity */
  background-color: rgba(255, 165, 0, 0.5);
  font-weight: bold;
}

.table-orders {
  border-spacing: 0px;
  border-collapse: separate;
}

.table-orders > thead > tr > th, .table-orders > tbody > tr > th, .table-orders > tfoot > tr > th, .table-orders > thead > tr > td, .table-orders > tbody > tr > td, .table-orders > tfoot > tr > td {
  border-right: 1px solid #dee2e6;
}

/* first thead th , first tbody td , first thead td add border-left */
.table-orders > thead > tr > th:first-child, .table-orders > tbody > tr > th:first-child, .table-orders > thead > tr > td:first-child, .table-orders > tbody > tr > td:first-child {
  border-left: 1px solid #dee2e6;
}